export const NavbarMenu = [
 
    {
      "id": 1,
      "name": "Home",
      "url": "/"
    },
    {
      "id": 2,
      "name": "Courses",
      "url": "/courses/101"
    },
    {
      "id": 3,
      "name": "Resources",
      "url": "/products"
    },
    {
      "id": 4,
      "name": "Session",
      "url": "/bookcall"
    },
    {
      "id": 5,
      "name": "About",
      "url": "/about"
    },
    
    
  
  ]